import { type ReactNode } from 'react';
import { useRootScope, useSuspenseCurrentUser } from 'FrontRoyalAngular';
import { type CurrentUser } from 'Users';
import { ForcedInitialRedirectRoute } from './ForcedInitialRedirectRoute';
import { RedirectToSignIn } from './RedirectToSignIn';

type AuthenticatedRouteProps = {
    allowedWhenOnboardingIncomplete: boolean;
    canAccessRoute: ((currentUser: CurrentUser) => boolean) | undefined;
    children: ReactNode;
};

export function AuthenticatedRoute({
    allowedWhenOnboardingIncomplete,
    canAccessRoute,
    children,
}: AuthenticatedRouteProps) {
    const currentUser = useSuspenseCurrentUser();
    const $rootScope = useRootScope();

    // The authentication sequence has finished, but we don't have a currentUser.
    // This means the user is not authenticated, so we redirect to the sign-in page.
    if (!currentUser) {
        if ($rootScope.reactStillNeedsToHandleSignOut) {
            $rootScope.reactStillNeedsToHandleSignOut = false;
            return null;
        }
        return <RedirectToSignIn />;
    }

    return (
        <ForcedInitialRedirectRoute
            allowedWhenOnboardingIncomplete={allowedWhenOnboardingIncomplete}
            canAccessRoute={canAccessRoute}
        >
            {children}
        </ForcedInitialRedirectRoute>
    );
}
