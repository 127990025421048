import { InstitutionId } from 'Institutions';
import { Brand, formattedBrandName } from 'AppBranding';
import { ProgramPreferencesStateKey } from 'Careers';
import { PHYSICAL_DIPLOMA, DELIVERED_DIGITAL_CERTIFICATE, DYNAMIC_DOWNLOADABLE_CERTIFICATE } from './constants';
import buildConfig from './buildConfig';
import { ProgramFamilyConfigs } from './ProgramFamilyConfigs';
import { type ProgramTypeConfig, ProgramFamily, ProgramType, ProgramField } from './Program.types';
import { welcomePackageConfigs } from './welcomePackageConfigs';

/*

    How to move a property from schedulable.js to here:

    1. Determine the current value in schedulable.js for each program type. This can be done either by reading the code in schedulable.js
        or by using the `audit` tool defined in schedulable.js (search there for instructions)
    2. Add the property to the ProgramTypeConfig type
    3. Determine which level(s) the value should be set at. If this is something specific for each program, like `label`, then
        it would be set in the configuration for the specific program type. If, for example, all of the degree programs share
        the same value, then it could be set in DefaultDegreeConfig

    4. Set the property in the appropriate places in this file
        * dynamic properties must be created by assigning a function as the value. The argument to the function will
            be the full config object, making it possible to build certain values by combining other values on the object.
            For an example, see `networkProgramTitle`.
        * NOTE: If you don't make dynamic properties a function, TS will error saying the property is incompatible because it's readonly.
    5. Remove all the places in schedulable.js where the property is set. Do not remove calls to delegateToProgramType
        or references in schedulable.js that use the property to determine other things.
    5. Check that you have not accidentally changed any values:
        * In `main` and in your branch, run `console.log(JSON.stringify($('[ng-controller]').injector().get('Cohort').audit(keys)))`
        * Compare the results. One way to do this is to copy the results to files in vscode, format as json, and use the "Compare Selected" tool

*/

const DefaultConfig = {
    isMBA: false,
    isEMBA: false,
    isMSBA: false,
    isMSSE: false,
    isExecEd: false,
    isDegreeProgram: false,
    supportsSchedule: false,
    supportsNetworkAccess: false,
    networkProgramTitle: null,
    requiresTILADisclosure: false,
    supportsBotPage: false,
    supportsSlackRooms: false,
    completionDocumentType: null,
    requiresProctoring: false,
    showApplicationScholarshipEligibilityNotice: false,
    supportsBuyNowPayLater: false,
    supportsStudentDashboardConfig: true,
    supportsTranscriptDownload: false,
    supportsProgramApplications: false,
    supportsManuallyApplyingToProgram: false,
    supportsAutoDeclineAdmissionOffersDate: false,
    supportsActivateExecEdDates: false,
    supportsCourseSummary: false,
    supportsEnrollmentVerificationLetter: false,
    standaloneShortProgramTitle: ({ shortProgramTitle }: ProgramTypeConfig) => shortProgramTitle,
    applicationProgramTypeHeader: null,

    // Because of how we're using these `programPreferenceStateKeys` in `determineTargetProgramType`,
    // there can't be any overlap with the `programPreferenceStateKeys` in other program type configs
    // belonging to the same institution.
    programPreferenceStateKeys: () => [],
    supportsSharedExternalScheduleUrl: false,
    welcomePackageConfiguration: null,
    studentSpotlightCarouselProps: { streamIds: [] } as ProgramTypeConfig['studentSpotlightCarouselProps'],
} as const;

const DefaultDegreeConfig = {
    ...DefaultConfig,
    isDegreeProgram: true,
    learnMoreUrlBrandConfigProperty: 'learnMoreAboutProgramsUrl',
    supportsNetworkAccess: true,
    supportsSchedule: true,
    supportsBotPage: true,
    requiresTILADisclosure: true,
    transcriptProgramTitle: null,
    networkProgramTitle: ({ branding, shortProgramTitle }: ProgramTypeConfig) =>
        `${formattedBrandName('short', branding)} ${shortProgramTitle}`,
    supportsSlackRooms: true,
    completionDocumentType: PHYSICAL_DIPLOMA,
    requiresProctoring: true,
    supportsProgramApplications: true,
    supportsManuallyApplyingToProgram: true,
    supportsActivateExecEdDates: true,
    supportsEnrollmentVerificationLetter: true,
    supportsCourseSummary: true,
    supportsTranscriptDownload: true,
} as const;

const DefaultQuanticConfig = {
    institutionID: InstitutionId.quantic,
    branding: Brand.quantic,
} as const;

const DefaultValarConfig = {
    institutionID: InstitutionId.valar,
    branding: Brand.valar,
} as const;

const DefaultSmartlyConfig = {
    institutionID: InstitutionId.smartly,
    branding: Brand.smartly,
} as const;

// I'm assuming we'll need this. If this is still empty once everything is
// copied over, then remove it
const DefaultCertificateConfig = {
    ...DefaultConfig,
} as const;

const DefaultExecEdConfig = {
    ...DefaultConfig,
    ...DefaultQuanticConfig,
    ...ProgramFamilyConfigs[ProgramFamily.exec_ed_certificate],
    learnMoreUrlBrandConfigProperty: 'learnMoreAboutProgramsUrl',
    supportsSchedule: true,
    isExecEd: true,
    supportsNetworkAccess: true,
    supportsBotPage: true,
    networkProgramTitle: ({ fullTitle }: ProgramTypeConfig) => `Quantic Executive Education: ${fullTitle}`,
    supportsSlackRooms: false, // We may want to add slack to some exec ed programs in the future, but for now it's not supported
    completionDocumentType: DELIVERED_DIGITAL_CERTIFICATE,
    supportsBuyNowPayLater: true,
    supportsProgramApplications: true,
    supportsAutoDeclineAdmissionOffersDate: true,
    supportsSharedExternalScheduleUrl: true,
    supportsEnrollmentVerificationLetter: true,
    welcomePackageConfiguration: welcomePackageConfigs.execEd,
    libraryDatabases: [] as ProgramTypeConfig['libraryDatabases'],
} as const;

const DefaultVLAProgramTypeConfig = {
    ...DefaultConfig,
    programField: ProgramField.valar_leadership_accelerator,
    ...ProgramFamilyConfigs[ProgramFamily.valar_leadership_accelerator],
    institutionID: InstitutionId.valar,
    supportsSchedule: true,
    branding: Brand.valar,
    shortProgramTitle: 'VLA',
    supportsStudentDashboardConfig: true,

    // See Program/constants.ts for details on the VLA certificate
    completionDocumentType: DELIVERED_DIGITAL_CERTIFICATE,
    libraryDatabases: [] as ProgramTypeConfig['libraryDatabases'],
} as const;

const isEMBA = {
    programField: ProgramField.master_of_business_administration,
    ...ProgramFamilyConfigs[ProgramFamily.master_of_business_administration],
    isEMBA: true,
    learnMoreUrlBrandConfigProperty: 'learnMoreAboutEmbaUrl',
    shortProgramTitle: 'EMBA',
    showApplicationScholarshipEligibilityNotice: true,
    fullTitle: 'Executive MBA',
    libraryDatabases: ['statista', 'one_business', 'ibisworld'] as ProgramTypeConfig['libraryDatabases'],

    // See comment for `programPreferenceStateKeys` in `DefaultConfig`.
    programPreferenceStateKeys: () => [
        ProgramPreferencesStateKey.business_admin_middle_experience,
        ProgramPreferencesStateKey.business_admin_younger_with_high_experience,
        ProgramPreferencesStateKey.business_admin_older_with_high_experience,
    ],
} as const;

const isMBA = {
    programField: ProgramField.master_of_business_administration,
    ...ProgramFamilyConfigs[ProgramFamily.master_of_business_administration],
    isMBA: true,
    // This is used in the admissions process modals before submitting application,
    // and we use the EMBA site even when a signup's default program type is MBA
    learnMoreUrlBrandConfigProperty: 'learnMoreAboutEmbaUrl',
    shortProgramTitle: 'MBA',
    showApplicationScholarshipEligibilityNotice: true,
    fullTitle: 'MBA',
    libraryDatabases: ['statista', 'one_business', 'ibisworld'] as ProgramTypeConfig['libraryDatabases'],

    // See comment for `programPreferenceStateKeys` in `DefaultConfig`.
    programPreferenceStateKeys: () => [
        ProgramPreferencesStateKey.business_admin_minimum_experience,
        ProgramPreferencesStateKey.business_admin_younger_with_low_experience,
        ProgramPreferencesStateKey.business_admin_older_with_low_experience,
    ],
} as const;

const mbaAndEmbastudentSpotlightCarouselProps = {
    streamIds: [
        '48097b931760f6181a4519979bdbe1b7',
        'cf2e845bb11902c06b883541c5168e19',
        '8b4772513d0f224728ebb48ff95c81af',
        '2d9b88500adac51711584d1e1650b25f',
        '52dd5b720075b5288519030ccf098fed',
        'b07f335f184c2b6a0f821e2894a77975',
        '6f013d4791218ef56089f7c7b502493c',
        '274b390897ebd892b72efdb05c796bc8',
        '2e01fbb52627e83f9b55593ca9105293',
        '52177b598fece210c62a6e914c47eccc',
        '6e067d87d7cd693238710357ff3dcd6c',
        '00953f8a700491b3f0a56a52b36a9fbf',
        '09195245efdcc79219ba021c7b134373',
        'e190cd25887c5f28e26a01dd156fea9c',
        '09ff62f2e306f4d29d7af417fb1a6bbf',
        '46b82bfa2870bb996d8b39fba31f30ee',
    ],
};

const valarMbaAndEmbaStudentSpotlightCarouselProps = {
    streamIds: [
        '0b31d3c42f5f5b60cf396e9a72cad12f',
        '0f4b4c1f2e1bbf26c3791637927ea4d1',
        'b4fc6c44a931f502040e7da62a5d57f2',
        '86c9eeb319c9d3dbc1b5a5da823b43f7',
        '7fdeb36fde440924b195a82009cf7d66',
    ],
    showInstitutionLogo: true,
};

export const ProgramTypeConfigs: Record<ProgramType, ProgramTypeConfig> = {
    /* Quantic Degree Programs */
    [ProgramType.emba]: buildConfig({
        ...DefaultDegreeConfig,
        ...DefaultQuanticConfig,
        ...isEMBA,
        key: ProgramType.emba,
        label: 'EMBA', // internal
        applicationProgramTypeHeader: 'settings.settings.application_program_type_mba_emba',
        standardMonthlyPayment: 950,
        welcomePackageConfiguration: welcomePackageConfigs.emba,
        transcriptProgramTitle: 'Executive Master of Business Administration',
        studentSpotlightCarouselProps: mbaAndEmbastudentSpotlightCarouselProps,
    }),
    [ProgramType.mba]: buildConfig({
        ...DefaultDegreeConfig,
        ...DefaultQuanticConfig,
        ...isMBA,
        key: ProgramType.mba,
        label: 'MBA', // internal
        applicationProgramTypeHeader: 'settings.settings.application_program_type_mba_emba',
        standardMonthlyPayment: 950,
        welcomePackageConfiguration: welcomePackageConfigs.mba,
        transcriptProgramTitle: 'Master of Business Administration',
        studentSpotlightCarouselProps: mbaAndEmbastudentSpotlightCarouselProps,
    }),
    [ProgramType.msba]: buildConfig({
        ...DefaultDegreeConfig,
        ...DefaultQuanticConfig,
        ...ProgramFamilyConfigs[ProgramFamily.master_of_science_in_business_analytics],
        key: ProgramType.msba,
        programField: ProgramField.master_of_science_in_business_analytics,
        isMSBA: true,
        learnMoreUrlBrandConfigProperty: 'learnMoreAboutMsbaUrl',
        label: 'MSBA', // internal
        shortProgramTitle: 'MSBA',
        fullTitle: 'MS in Business Analytics',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_msba',
        standardMonthlyPayment: 950,
        welcomePackageConfiguration: welcomePackageConfigs.msba,
        transcriptProgramTitle: 'Master of Science in Business Analytics',
        libraryDatabases: ['statista', 'one_business'],
        studentSpotlightCarouselProps: {
            streamIds: [
                '8889a693db6348253a1c6b997299506c',
                '8d26a4bb2df39b1a4ef4b5b6cb662448',
                '50fb20d27a45adffd063700b7f5b3327',
                '15dec0e73019fc35d840529e7a3e987a',
                '020574561588721776f193ad4a2205a7',
            ],
        },
    }),
    [ProgramType.msse]: buildConfig({
        ...DefaultDegreeConfig,
        ...DefaultQuanticConfig,
        ...ProgramFamilyConfigs[ProgramFamily.master_of_science_in_software_engineering],
        key: ProgramType.msse,
        programField: ProgramField.master_of_science_in_software_engineering,
        isMSSE: true,
        learnMoreUrlBrandConfigProperty: 'learnMoreAboutMsseUrl',
        label: 'MSSE', // internal
        shortProgramTitle: 'MSSE',
        fullTitle: 'MS in Software Engineering',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_msse',
        standardMonthlyPayment: 950,
        welcomePackageConfiguration: welcomePackageConfigs.msse,
        transcriptProgramTitle: 'Master of Science in Software Engineering',
        libraryDatabases: ['one_business'],
        studentSpotlightCarouselProps: {
            streamIds: [
                '15dec0e73019fc35d840529e7a3e987a',
                '8d26a4bb2df39b1a4ef4b5b6cb662448',
                '50fb20d27a45adffd063700b7f5b3327',
                '8889a693db6348253a1c6b997299506c',
                '020574561588721776f193ad4a2205a7',
            ],
        },
    }),
    /* Quantic Degree Programs */

    /* Valar Degree Programs */
    [ProgramType.mba_leadership]: buildConfig({
        ...DefaultDegreeConfig,
        ...DefaultValarConfig,
        ...isMBA,
        key: ProgramType.mba_leadership,
        label: 'MBALM', // internal
        applicationProgramTypeHeader: 'settings.settings.application_program_type_mba_emba',
        standardMonthlyPayment: 800,
        welcomePackageConfiguration: welcomePackageConfigs.mbaLeadership,
        transcriptProgramTitle: 'Master of Business Administration in Leadership and Management',
        studentSpotlightCarouselProps: valarMbaAndEmbaStudentSpotlightCarouselProps,
    }),
    [ProgramType.emba_strategic_leadership]: buildConfig({
        ...DefaultDegreeConfig,
        ...DefaultValarConfig,
        ...isEMBA,
        key: ProgramType.emba_strategic_leadership,
        label: 'EMBASL', // internal
        applicationProgramTypeHeader: 'settings.settings.application_program_type_mba_emba',
        standardMonthlyPayment: 800,
        welcomePackageConfiguration: welcomePackageConfigs.embaStrategicLeadership,
        transcriptProgramTitle: 'Executive Master of Business Administration in Strategic Leadership',
        studentSpotlightCarouselProps: valarMbaAndEmbaStudentSpotlightCarouselProps,
    }),
    /* Valar Degree Programs */

    /* Exec Ed Certs */
    [ProgramType.ai_for_technical_leaders_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.ai_for_technical_leaders_cert,
        programField: ProgramField.ai_for_technical_leaders_cert,
        label: 'TECH-AI-CERT',
        shortProgramTitle: 'AI for Technical Leaders',
        fullTitle: 'AI for Technical Leaders',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_ai_for_technical_leaders_cert',
        transcriptProgramTitle: 'AI for Technical Leaders',
    }),
    [ProgramType.bus_analytics_leaders_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.bus_analytics_leaders_cert,
        programField: ProgramField.bus_analytics_leaders_cert,
        label: 'BAL-CERT',
        fullTitle: 'Business Analytics for Leaders',
        shortProgramTitle: 'Business Analytics for Leaders',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_bus_analytics_leaders_cert',
        transcriptProgramTitle: 'Business Analytics for Leaders',
    }),
    [ProgramType.cmo_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.cmo_cert,
        programField: ProgramField.cmo_cert,
        label: 'CMO-CERT',
        fullTitle: 'CMO Program',
        shortProgramTitle: 'CMO',
        standaloneShortProgramTitle: 'CMO Program',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_cmo_cert',
        transcriptProgramTitle: 'Chief Marketing Officer Program',
    }),
    [ProgramType.cto_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.cto_cert,
        programField: ProgramField.cto_cert,
        label: 'CTO-CERT',
        fullTitle: 'CTO Program',
        shortProgramTitle: 'CTO',
        standaloneShortProgramTitle: 'CTO Program',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_cto_cert',
        transcriptProgramTitle: 'Chief Technology Officer Program',
    }),
    [ProgramType.data_science_foundations_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.data_science_foundations_cert,
        programField: ProgramField.data_science_foundations_cert,
        label: 'DSF-CERT',
        fullTitle: 'Data Science Foundations',
        shortProgramTitle: 'Data Science Foundations',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_data_science_foundations_cert',
        transcriptProgramTitle: 'Data Science Foundations',
    }),
    [ProgramType.executive_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.executive_cert,
        programField: ProgramField.executive_cert,
        label: 'EXEC-CERT',
        fullTitle: 'Executive Program',
        shortProgramTitle: 'Executive',
        standaloneShortProgramTitle: 'Executive Program',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_executive_cert',
        transcriptProgramTitle: 'Executive Program',
    }),
    [ProgramType.fin_for_non_fin_managers_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.fin_for_non_fin_managers_cert,
        programField: ProgramField.fin_for_non_fin_managers_cert,
        label: 'FIN-ACCT-CERT',
        fullTitle: 'Finance & Accounting for Managers',
        shortProgramTitle: 'Finance & Accounting for Managers',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_fin_for_managers_cert',
        transcriptProgramTitle: 'Finance & Accounting for Managers',
    }),
    [ProgramType.founders_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.founders_cert,
        programField: ProgramField.founders_cert,
        label: 'ENT-CERT',
        fullTitle: 'Founders Program',
        shortProgramTitle: 'Founders',
        standaloneShortProgramTitle: 'Founders Program',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_founders_cert',
        transcriptProgramTitle: 'Founders Program',
    }),
    [ProgramType.learn_code_gpt_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.learn_code_gpt_cert,
        programField: ProgramField.learn_code_gpt_cert,
        label: 'CODE-CERT',
        fullTitle: 'Learn to Code Using ChatGPT',
        shortProgramTitle: 'Learn to Code Using ChatGPT',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_learn_code_gpt_cert',
        transcriptProgramTitle: 'Learn to Code Using ChatGPT',
    }),
    [ProgramType.prototype_gpt_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.prototype_gpt_cert,
        programField: ProgramField.prototype_gpt_cert,
        label: 'PROTO-CERT',
        fullTitle: 'AI for Building Software',
        shortProgramTitle: 'AI for Building Software',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_prototype_gpt_cert',
        transcriptProgramTitle: 'AI for Building Software',
    }),
    [ProgramType.tyb_ai_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.tyb_ai_cert,
        programField: ProgramField.tyb_ai_cert,
        label: 'TYB-AI-CERT',
        fullTitle: 'Transform Your Business with AI & ChatGPT',
        shortProgramTitle: 'Transform Your Business with AI & ChatGPT',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_tyb_ai_cert',
        transcriptProgramTitle: 'Transform Your Business with AI & ChatGPT',
    }),
    [ProgramType.tyb_blockchain_cert]: buildConfig({
        ...DefaultExecEdConfig,
        key: ProgramType.tyb_blockchain_cert,
        programField: ProgramField.tyb_blockchain_cert,
        label: 'TYB-BLOCK-CERT',
        fullTitle: 'Transform Your Business with Blockchain',
        shortProgramTitle: 'Transform Your Business with Blockchain',
        applicationProgramTypeHeader: 'settings.settings.application_program_type_tyb_blockchain_cert',
        transcriptProgramTitle: 'Transform Your Business with Blockchain',
    }),
    /* Exec Ed Certs */

    /* VLA */
    [ProgramType.vla]: buildConfig({
        ...DefaultVLAProgramTypeConfig,
        key: ProgramType.vla,
        label: 'VLA', // internal
        fullTitle: 'Valar Leadership Accelerator',
        transcriptProgramTitle: 'Valar Leadership Accelerator',
    }),
    [ProgramType.vla_aspiring_managers]: buildConfig({
        ...DefaultVLAProgramTypeConfig,
        key: ProgramType.vla_aspiring_managers,
        label: 'VLA-AM', // internal
        fullTitle: 'Valar Leadership Accelerator for Aspiring Leaders and Managers',
        transcriptProgramTitle: 'Valar Leadership Accelerator for Aspiring Leaders and Managers',
    }),
    /* VLA */

    /* Other programs */
    [ProgramType.the_business_certificate]: buildConfig({
        ...DefaultCertificateConfig,
        ...DefaultSmartlyConfig,
        ...ProgramFamilyConfigs[ProgramFamily.the_business_certificate],
        key: ProgramType.the_business_certificate,
        programField: ProgramField.the_business_certificate,
        institutionID: InstitutionId.smartly,
        label: 'The Business Certificate (free)',
        fullTitle: 'Fundamentals of Business Certificate',
        shortProgramTitle: 'Fundamentals of Business Certificate',
        completionDocumentType: DYNAMIC_DOWNLOADABLE_CERTIFICATE,
        supportsProgramApplications: true,
        libraryDatabases: [],
    }),
    [ProgramType.jordanian_math]: buildConfig({
        ...DefaultConfig,
        ...ProgramFamilyConfigs[ProgramFamily.jordanian_math],
        key: ProgramType.jordanian_math,
        programField: ProgramField.jordanian_math,
        institutionID: InstitutionId.miya_miya,
        branding: Brand.miya_miya,
        label: 'Jordanian Math',
        shortProgramTitle: 'High School STEM',
        fullTitle: '',
        libraryDatabases: [],
    }),
    [ProgramType.external]: buildConfig({
        // External
        // This is the minimal config necessary (that I've identified so far) for an external
        // program type to function with a career profile. It's not necessarily intended to be
        // used long-term, but it's useful for now to stop the Applicant Admin -> Edit Profile
        // page from throwing errors when it looks up these keys.
        ...DefaultConfig,
        ...DefaultSmartlyConfig,
        key: ProgramType.external,
        programField: null,
        label: 'External',
        shortProgramTitle: '',
        fullTitle: '',
        supportsStudentDashboardConfig: false,
        libraryDatabases: [],
    }),
    // The `demo` program type was not defined in schedulable.js.
    // Because it is defined in the ProgramType type, typescript wants entries for it
    // All of these values should be unused in practice, so it doesn't matter what they're set to
    [ProgramType.demo]: buildConfig({
        ...DefaultConfig,
        ...DefaultQuanticConfig,
        key: ProgramType.demo,
        programField: null,
        label: '',
        shortProgramTitle: '',
        fullTitle: '',
        transcriptProgramTitle: '',
        libraryDatabases: [],
    }),
    /* Other programs */

    /* Deprecated Programs */
    [ProgramType.career_network_only]: buildConfig({
        ...DefaultConfig,
        ...DefaultSmartlyConfig,
        key: ProgramType.career_network_only,
        programField: ProgramField.career_network_only,
        label: 'Careers Network Only', // internal
        shortProgramTitle: '',
        fullTitle: '',
        transcriptProgramTitle: 'Careers Network Only',
        libraryDatabases: [],
    }),
    /* Deprecated Programs */
};
