import { type Nullable } from '@Types';
import { type ProgramType } from 'Program';
import { type DeferralReason } from 'DeferralReason';
import { type Scholarship } from 'Scholarship';
import { type LearnerProject } from 'LearnerProjects';

export enum ProgramInclusionStatus {
    Included = 'included',
    Graduated = 'graduated',
    WillNotGraduate = 'will_not_graduate',
}

// This type is what gets passed down from the server in the
// curriculum_status property. See CurriculumStatus type below
export enum RawCurriculumStatus {
    Failed = 'failed',
    Honors = 'honors',
    Graduated = 'graduated',
    Finished = 'finished',
    Week0 = 'week_0',
    Week1 = 'week_1',
    NotOnTrack = 'not_on_track',
    AlmostThere = 'almost_there',
    OnTrackFinished = 'on_track_finished',
    OnTrack = 'on_track',
    PreGraduationFinished = 'pre_graduation_finished',
    PreGraduationNotOnTrack = 'pre_graduation_not_on_track',
    PreGraduationAlmostThere = 'pre_graduation_almost_there',
    WithdrawnOrExpelled = 'withdrawn_or_expelled',
    MissingGraduationRequirements = 'missing_graduation_requirements',
}

// This type is returned by the getCurriculumStatus function, which builds it
// based on the RawCurruculumStatus type above and the state of the cohort. We
// can clean this up eventually, but backwards-compatibility issues for us to wait.
// See comment in getCurriculumStatus
export enum CurriculumStatus {
    Failed = 'failed',
    Honors = 'honors',
    Graduated = 'graduated',
    PreGraduationFinished = 'pre_graduation_finished',
    PreGraduationNotOnTrack = 'pre_graduation_not_on_track',
    PreGraduationAlmostThere = 'pre_graduation_almost_there',
    Week0 = 'week_0',
    Week1 = 'week_1',
    NotOnTrack = 'not_on_track',
    AlmostThere = 'almost_there',
    OnTrackFinished = 'on_track_finished',
    OnTrack = 'on_track',
    WithdrawnOrExpelled = 'withdrawn_or_expelled',
    MissingGraduationRequirements = 'missing_graduation_requirements',
}

export interface UnlockedStreamsEntry {
    localePackId: string;
    unlockedUntil: number;
}

export interface ProgramInclusion {
    id: string;
    includedAt: number;
    contentUnlocked: boolean;
    cohortId: string;
    cohortName: string;
    status: ProgramInclusionStatus;
    includedCohortSectionId: string;
    tuitionContractId: string;
    scholarshipIds: string[];
    cumulativeScholarship: Nullable<Scholarship>;
    tuitionPlanId: string;
    cohortSlackRoomId: string;
    cohortProgramGuideUrl: string;
    willNotGraduateReason?: WillNotGraduateReason;
    admissionOfferId?: string;
    disableExamLocking: boolean;
    honorsIneligible: boolean;
    graduatedWithHonors: boolean;
    canUseDeferralLinkUntil: Nullable<number>;
    pendingDeferralReason: DeferralReason | undefined;
    historicalCohortIds: string[];
    programType: ProgramType;
    auditing: boolean;
    shareableWithClassmates?: boolean;
    unlockedStreams: UnlockedStreamsEntry[];
    requiresTilaDisclosure: boolean;
    studentNetworkActivated: boolean;
    current: boolean;
    updatedAt: number;
    userId: string;
    graduatedAt: Nullable<number>;
    curriculumStatus: Nullable<RawCurriculumStatus>;
    academicHold: boolean;
    academicProbationStatus: Nullable<AcademicProbationStatus>;
    lockedDueToBillingIssue: boolean;
    pastDueProjects: LearnerProject[];
    maxDeferrableGraduationDate: number;
    meetsGraduationRequirements: boolean;
    numRequiredSpecializationsRemaining: number;
    graduationExtensionEndsAt: Nullable<number>;
}

export interface WillNotGraduateReason {
    id: string;
    category: WillNotGraduateReasonCategory;
    title: string;
}

export enum WillNotGraduateReasonCategory {
    expulsion = 'expulsion',
    withdrawal = 'withdrawal',
    failed = 'failed',
    unknown = 'unknown',
}

export enum AcademicProbationStatus {
    first_probation_no_hold = 'first_probation_no_hold',
    second_probation_on_hold = 'second_probation_on_hold',
}

export enum HumanReadableAcademicProbationStatus {
    first_probation_no_hold = 'First Probation - No Hold',
    second_probation_on_hold = 'Second Probation - On Hold',
}
