import { type ReactNode, useEffect, useState } from 'react';
import { useCurrentUser } from 'FrontRoyalAngular';
import { type CurrentUser } from 'Users';
import { RedirectToSignIn } from './RedirectToSignIn';

type HasAppropriateAccessRouteProps = {
    canAccessRoute: (currentUser: CurrentUser) => boolean;
    children: ReactNode;
};

export function HasAppropriateAccessRoute({ canAccessRoute, children }: HasAppropriateAccessRouteProps) {
    const currentUser = useCurrentUser()!;
    const [hasAppropriateAccess, setHasAppropriateAccess] = useState(() => canAccessRoute(currentUser));

    // If the user loses access to the route in the middle of their session,
    // we need to redirect them away from the current route. We redirect them
    // to the sign-in page, which will then redirect them to the dashboard.
    useEffect(() => {
        setHasAppropriateAccess(canAccessRoute(currentUser));
    }, [canAccessRoute, currentUser]);

    if (!hasAppropriateAccess) {
        return <RedirectToSignIn />;
    }

    return children;
}
