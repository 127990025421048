import { type ReactNode } from 'react';
import { type CurrentUser } from 'Users';
import { useAngularContext } from 'AngularContext';
import { type FrontRoyalRouteService } from 'NavigationHelpers';
import { RedirectToSignIn } from './RedirectToSignIn';
import { AuthenticatedRoute } from './AuthenticatedRoute';

type ProtectedRouteProps = {
    allowedWhenOnboardingIncomplete?: boolean;
    canAccessRoute?: (currentUser: CurrentUser) => boolean;
    children: ReactNode;
};

// This component is used to protect routes that should only be accessible to authenticated users.
// It suspends until the authentication sequence is complete. Once complete, the route is rendered
// if the user was successfully authenticated. If not, the user is redirected to the sign-in page.
export function ProtectedRoute({ allowedWhenOnboardingIncomplete, canAccessRoute, children }: ProtectedRouteProps) {
    const $injector = useAngularContext();
    const $route = $injector.get<FrontRoyalRouteService>('$route');
    const blockAuthenticatedAccess = $injector.get<{ block: boolean }>('blockAuthenticatedAccess');

    if ($route.current?.$$route?.routeHandledBy !== 'react') return null;
    if (blockAuthenticatedAccess.block) return <RedirectToSignIn />;

    return (
        <AuthenticatedRoute
            allowedWhenOnboardingIncomplete={allowedWhenOnboardingIncomplete ?? false}
            canAccessRoute={canAccessRoute}
        >
            {children}
        </AuthenticatedRoute>
    );
}
