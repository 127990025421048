import amiraRouxAvatar from 'images/photos/amira-roux.png';
import yanruTanAvatar from 'images/photos/yanru-tan.jpg';
import omkarWalkeAvatar from 'images/photos/omkar-walke.jpg';
import kennethSmithAvatar from 'images/photos/kenneth-smith.jpg';
import heleseSmauldonAvatar from 'images/photos/helese-smauldon.jpg';
import damolaBamgboyeAvatar from 'images/photos/damola-bamgboye.jpg';
import { type WelcomePackageStudent } from './Program.types';

export const students: Record<string, WelcomePackageStudent> = {
    LeondraJames: {
        name: 'Leondra James',
        locationString: 'Los Angeles, CA, USA',
        jobTitle: 'Director Data Analysis',
        company: 'Wpromote',
        university: 'Carnegie Mellon University',
        degree: 'M.A.',
        avatarUrl:
            'https://uploads.smart.ly/avatar_assets/af4e7f61-dbd0-4a59-bcc8-0b574bd750d2/a46cf60f15ef17f32ae39b90ad9455c9.1584842195.open-uri20200322-14001-1vwih0y',
    },
    KristenNogard: {
        name: 'Kristen Nogard',
        locationString: 'Raleigh, NC, USA',
        jobTitle: 'Senior Manager, Capacity Planning',
        company: 'Amazon',
        university: 'University of North Carolina Greensboro',
        degree: 'B.A.',
        avatarUrl:
            'https://uploads.smart.ly/avatar_assets/66564afd-4725-443c-9a13-91085117c7c3/f740955a1660db64a010daf8305ef57c.1616078158.blob',
    },
    HyderAlikhan: {
        name: 'Hyder Alikhan',
        locationString: 'Houston, TX, USA',
        jobTitle: 'Senior Specialist',
        company: 'Bain and Co.',
        university: 'University of Pennsylvania',
        degree: 'M.Sc.',
        avatarUrl:
            'https://uploads.smart.ly/avatar_assets/580d359d-28dc-467a-987a-00e0e9da6c02/1bf5a472aa4e3f1e5fb94d7d24ae0b61.1586978842.open-uri20200415-11439-11i7by7',
    },
    TomGarvey: {
        name: 'Tom Garvey',
        locationString: 'London, England',
        jobTitle: 'Senior Business Analyst',
        company: 'Google',
        university: 'University of Oxford',
        degree: 'M.Sc.',
        avatarUrl:
            'https://uploads.smart.ly/avatar_assets/c818c7b6-2a1e-40f5-b59a-4f313687f787/ba33f6436ad62a545598fc113d5a3661.1564740132.Tom.jfif',
    },
    MarieWeijler: {
        name: 'Marie Weijler',
        locationString: 'Amsterdam, Netherlands',
        jobTitle: 'Investment Manager',
        company: 'Cottonwood Technology Fund',
        university: 'University of Leuven',
        degree: 'M.A.',
        avatarUrl:
            'https://uploads.smart.ly/avatar_assets/044856c5-6efc-4402-9102-d3642c3ac6b6/d4ac4a51039b4212c3fe9b8ce2a420df.1599305270.Marie_Weijler3.jpg',
    },
    DanielNiego: {
        name: 'Daniel Niego',
        locationString: 'Lima, Peru',
        jobTitle: 'Co-Founder',
        company: 'Doktuz',
        university: 'Columbia University',
        degree: 'M.S.',
        avatarUrl:
            'https://uploads.smart.ly/avatar_assets/57605d32-e1fc-4f75-8b79-66c74ceb33c1/ebb32915ae3008b1b684b8ce18d5e5a6.1574315225.Daniel_1.jpg',
    },
    WeichaoRachelZhai: {
        name: 'Weichao Rachel Zhai',
        locationString: 'Singapore, SG',
        jobTitle: 'Project Lead',
        company: 'A*Star',
        university: 'Stanford University',
        degree: 'B.Sc.',
        avatarUrl:
            'https://uploads.smart.ly/avatar_assets/30fefe2a-83b5-4626-a6ac-2dc84af6f816/fbf8149c143eb170bac93fbc3694736f.1578038649.open-uri20200103-31105-80jc1f',
    },
    AlexeyDubrovskiy: {
        // dubrovskiyad@gmail.com
        name: 'Alexey Dubrovskiy',
        locationString: 'Zürich, CH',
        jobTitle: 'Senior Software Engineer',
        company: 'Google',
        university: 'Lomonosov Moscow State University',
        degree: 'Ph.D.',
        avatarUrl:
            'https://uploads.smart.ly/emba_welcome_package/profiles/bf5b23539980d40437fbe8d0a9b5e7f5.1571041476.jpeg',
    },
    HuiqiWang: {
        // angelhuiqi@hotmail.com
        name: 'Huiqi Wang',
        locationString: 'Princeton, NJ, US',
        jobTitle: 'Assistant Vice President',
        company: 'Bank of America',
        university: 'Rutgers University',
        degree: 'M.S.',
        avatarUrl:
            'https://uploads.smart.ly/emba_welcome_package/profiles/c39dc803c9eda10518f3da1e4183d873.1579571698.jpeg',
    },
    KelechiDavidEluwa: {
        // kdeluwa@gmail.com
        name: 'Kelechi David Eluwa',
        locationString: 'Oro Valley, AZ, US',
        jobTitle: 'Senior Manager, Systems Integration',
        company: 'ROCHE',
        university: 'Johns Hopkins University',
        degree: 'M.S.',
        avatarUrl:
            'https://uploads.smart.ly/emba_welcome_package/profiles/1016d224d004e0323d4418fe1ba58195.1574877586.Professional_Photo_1.jpg',
    },
    JessicaWatson: {
        // jessicawatson11@googlemail.com
        name: 'Jessica Watson',
        locationString: 'Canterbury, England, GB',
        jobTitle: 'Sales Strategy Leader E-Commerce',
        company: 'Procter & Gamble',
        university: 'University of Oxford',
        degree: 'B.A.Sc.',
        avatarUrl: 'https://uploads.smart.ly/emba_welcome_package/profiles/jessica.png',
    },
    BryanChuah: {
        // bryan.chuah@gmail.com
        name: 'Bryan Chuah',
        locationString: 'Singapore, SG',
        jobTitle: 'Regional Director',
        company: 'AIG',
        university: 'University of Michigan',
        degree: 'M.Sc.',
        avatarUrl:
            'https://uploads.smart.ly/emba_welcome_package/profiles/91ab4427f250aebc3b75d80986511c1b.1575107717.jpeg',
    },
    OmarWalke: {
        name: 'Omkar Walke',
        locationString: 'Atlanta, GA, US',
        jobTitle: 'Lead Project Manager',
        company: 'Honeywell',
        university: 'Clemson University',
        degree: 'M.S.',
        avatarUrl: omkarWalkeAvatar,
    },
    YanRuTan: {
        name: 'Yan Ru Tan',
        locationString: 'Singapore, SG',
        jobTitle: 'Chief of Staff & Vice President',
        company: 'Quantedge Advancement Initiative Ltd.',
        university: 'Nanyang Technological University',
        degree: 'B.A.',
        avatarUrl: yanruTanAvatar,
    },
    DamolaBamgboye: {
        name: 'Damola Bamgboye',
        locationString: 'Lagos, NG',
        jobTitle: 'Managing Director',
        company: 'Mercurie Labs',
        university: 'Covenant University',
        degree: 'B.Sc.',
        avatarUrl: damolaBamgboyeAvatar,
    },
    KennethSmith: {
        name: 'Kenneth Smith',
        locationString: 'Boston, MA, US',
        jobTitle: 'Founder & Co-CEO',
        company: 'Hopara',
        university: 'Simmons College',
        degree: 'M.Ed.',
        avatarUrl: kennethSmithAvatar,
    },
    HeleseSmauldon: {
        name: 'Helese Smauldon',
        locationString: 'Columbia, SC, US',
        jobTitle: 'Founder',
        company: 'Lemonseed, LLC',
        university: 'State University of New York Empire State College',
        degree: 'B.A.',
        avatarUrl: heleseSmauldonAvatar,
    },
    AmiraRoux: {
        name: 'Amira Roux',
        locationString: 'New York, NY, US',
        jobTitle: 'Senior Engineering Program Manager',
        company: 'Amazon',
        university: 'Clarkson University',
        degree: 'M.S.',
        avatarUrl: amiraRouxAvatar,
    },
};
