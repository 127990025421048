import { type ReactNode } from 'react';
import { type Location, Navigate, useLocation, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { type AnyObject } from '@Types';
import { type FrontRoyalRootScope, useCurrentUserIguanaObject, useRootScope } from 'FrontRoyalAngular';
import { type CurrentUserIguanaObject, type CurrentUser } from 'Users';
import { ROUTES as STUDENT_DASHBOARD_ROUTES } from 'StudentDashboard/constants';
import { RedirectWithTarget } from './RedirectWithTarget';
import { HasAppropriateAccessRoute } from './HasAppropriateAccessRoute';

type ForcedInitialRedirectRouteProps = {
    allowedWhenOnboardingIncomplete: boolean;
    canAccessRoute: ((currentUser: CurrentUser) => boolean) | undefined;
    children: ReactNode;
};

function shouldRedirectToConfirmProfile(igCurrentUser: CurrentUserIguanaObject, location: Location) {
    return !igCurrentUser.confirmed_profile_info && !location.pathname.includes('/complete-registration');
}

function shouldRedirectToSignUpTarget(igCurrentUser: CurrentUserIguanaObject) {
    return !(igCurrentUser as AnyObject).$$hasBeenRedirectedToApplication && Cookies.get('signup_target');
}

function shouldRedirectToApplication(
    igCurrentUser: CurrentUserIguanaObject,
    searchParams: URLSearchParams,
    location: Location,
    allowedWhenOnboardingIncomplete: ForcedInitialRedirectRouteProps['allowedWhenOnboardingIncomplete'],
) {
    return (
        !(igCurrentUser as AnyObject).$$hasBeenRedirectedToApplication &&
        searchParams.get('try_lesson') !== 'true' &&
        location.pathname.includes(STUDENT_DASHBOARD_ROUTES.STUDENT_DASHBOARD.path) &&
        igCurrentUser.shouldBeRedirectedToApplication &&
        !allowedWhenOnboardingIncomplete
    );
}

function shouldRedirectToBillingPage(
    igCurrentUser: CurrentUserIguanaObject,
    location: Location,
    $rootScope: FrontRoyalRootScope,
) {
    return (
        !(igCurrentUser as AnyObject).$$hasCompletedFirstLoad &&
        location.pathname.includes($rootScope.homePath) &&
        igCurrentUser.shouldVisitBillingPage
    );
}

export function ForcedInitialRedirectRoute({
    allowedWhenOnboardingIncomplete,
    canAccessRoute,
    children,
}: ForcedInitialRedirectRouteProps) {
    const igCurrentUser = useCurrentUserIguanaObject()!;
    const $rootScope = useRootScope();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    // NOTE: If this logic changes, please also update the logic in route_resolvers.js as necessary.
    if (shouldRedirectToConfirmProfile(igCurrentUser, location)) {
        // retain target redirect support since this can happen to older users who might be reloading a path
        return <RedirectWithTarget to="/complete-registration" />;
    }

    // If we have an override for where to send the user after logging in
    // NOTE: If this logic changes, please also update the logic in route_resolvers.js as necessary.
    if (shouldRedirectToSignUpTarget(igCurrentUser)) {
        const redirectTarget = Cookies.get('signup_target')!;
        Cookies.remove('signup_target', {
            path: '/',
        });

        // prevent any more redirects for this user session
        (igCurrentUser as AnyObject).$$hasBeenRedirectedToApplication = true;

        return <Navigate to={redirectTarget} replace />;
    }

    // On first loading the app, send the user to the application if they
    // need to fill one out. Unless deeplinking beyond the dashboard.
    //  You can also add `try_lesson` as a query param to
    // the route to bypass this behavior (e.g. `/dashboard?try_lesson=true`).
    // NOTE: If this logic changes, please also update the logic in route_resolvers.js as necessary.
    if (shouldRedirectToApplication(igCurrentUser, searchParams, location, allowedWhenOnboardingIncomplete)) {
        // Since $$hasBeenRedirectedToApplication is not stored, it will be
        // undefined on login/reload, preventing an infinite redirect loop.
        (igCurrentUser as AnyObject).$$hasBeenRedirectedToApplication = true;

        return <Navigate to="/settings/application" replace />;
    }

    // On first loading the app, send the user to the cohort billing page if necessary
    // NOTE: If this logic changes, please also update the logic in route_resolvers.js as necessary.
    if (shouldRedirectToBillingPage(igCurrentUser, location, $rootScope)) {
        // Might look odd at first glance, but the /settings/application_status route
        // serves as the cohort billing page.
        return <Navigate to="/settings/application_status" replace />;
    }

    // NOTE: If this logic changes, please also update the logic in route_resolvers.js as necessary.

    (igCurrentUser as AnyObject).$$hasCompletedFirstLoad = true;

    // Remove the try_lesson query param before we resolve to the desired route.
    // The user doesn't need to see it after we resolve the route and it mucks
    // up the URL unnecessarily.
    if (searchParams.get('try_lesson')) {
        searchParams.delete('try_lesson');
        return <Navigate to={`${location.pathname}?${searchParams.toString()}`} replace />;
    }

    // The authentication sequence has finished and we have a currentUser.
    // This means the user was successfully authenticated, so we render the protected route.
    if (canAccessRoute) {
        return <HasAppropriateAccessRoute canAccessRoute={canAccessRoute}>{children}</HasAppropriateAccessRoute>;
    }

    return children;
}
